import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "gatsby-theme-stack/src/integration-layout";
export const _frontmatter = {
  "title": "Email",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/email.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Email`}</h1>
    <p>{`Create a shared mailbox`}</p>
    <hr></hr>
    <p>{`Scale your customer support with our email solution. Looking to create a shared mailbox to improve your customer support ? This integration lets you receive all the emails from your customers in your Crisp Inbox. You're now able to create a shared inbox for all your teams: Marketing, Sales and Support. Just provide emails like `}<a parentName="p" {...{
        "href": "mailto:contact@yourbrand.com"
      }}>{`contact@yourbrand.com`}</a>{`, `}<a parentName="p" {...{
        "href": "mailto:support@yourbrand.com"
      }}>{`support@yourbrand.com`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:sales@yourbrand.com"
      }}>{`sales@yourbrand.com`}</a>{` and manages your emails as a Team!`}</p>
    <h2>{`Benefits of creating a shared mailbox:`}</h2>
    <ul>
      <li parentName="ul">{`Avoid distraction by keeping all your emails in Crisp`}</li>
      <li parentName="ul">{`Be more productive with the Crisp Inbox features (Knowledge Base, Shortcuts, Assignments)`}</li>
      <li parentName="ul">{`Know when users read your emails`}</li>
      <li parentName="ul">{`Get your user information from the Crisp CRM`}</li>
      <li parentName="ul">{`Share, receive images and files`}</li>
      <li parentName="ul">{`Messages you receive are stored in the Crisp dashboard. Your chat history is safe.`}</li>
      <li parentName="ul">{`Use the Crisp search engine to retrieve previous conversations`}</li>
      <li parentName="ul">{`Unify your teams communication`}</li>
      <li parentName="ul">{`Unify your teams communication`}</li>
    </ul>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      